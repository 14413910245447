import type { CategoriesViewProps } from '../../types';
import * as React from 'react';
import { CollectionsE as CollectionsComponent } from '../../containers/CollectionsE';

export function View(props: CategoriesViewProps) {
  return (
    <>
      <CollectionsComponent {...props} />
    </>
  );
}
